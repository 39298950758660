// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div style="width: 100%;">
    <!-- confirmationDeleteModal -->
    <a-modal
      :title="$t('label.distributor.delete') + ' - ' + selectedToDeleteName"
      @ok="deleteDistributor()"
      @cancel="closeAction"
      :okText="$t('label.delete')"
      cancelText="Cancel"
      v-model:visible="openDeleteDistributorModal"
      :closable="true"
      centered>
      <DeleteOutlined :style="{fontSize: '40px', color: 'red'}" />
      <br/>
      <span>{{ $t('message.delete.distributor') }}</span>
      <br/>
      <span>{{ $t('message.delete.distributor2') }}</span>
    </a-modal>
    <!-- FakturaCsvModal -->
    <a-modal
      :title="$t('label.distributor.download.faktura.csv')"
      @ok="submitDownloadFakturaCsv"
      @cancel="closeAction"
      :okText="$t('label.download')"
      cancelText="Cancel"
      v-model:visible="openFakturaCsvModal"
      :closable="true"
      centered>
      <a-form
        v-ctrl-enter="submitDownloadFakturaCsv"
        :ref="formRefFaktura"
        :model="formFaktura"
        :rules="rulesFaktura"
        @finish="submitDownloadFakturaCsv"
        layout="vertical"
      >
        <a-spin :spinning="loading">
          <a-form-item
            ref="fakturaYear"
            name="fakturaYear"
            :label="$t('label.year')">
            <a-select v-model:value="formFaktura.fakturaYear" :placeholder="$t('label.year')" @change="formFaktura.fakturaMonth = undefined">
              <a-select-option v-for="year in getCurrentYearList" :key="year">
                {{ year }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            ref="fakturaMonth"
            name="fakturaMonth"
            :label="$t('label.month')">
            <a-select v-model:value="formFaktura.fakturaMonth" :placeholder="$t('label.month')">
              <a-select-option v-for="month in months" :key="month" :disabled="formFaktura.fakturaYear === getCurrentYear && months.indexOf(month) > getCurrentMonth">
                {{ $t('label.'+month) }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-spin>
      </a-form>
    </a-modal>
    <!-- Artikelumsatzdaten Modal -->
    <a-modal
      :title="$t('label.distributor.download.turnover.csv')"
      @ok="submitDownloadUmsatzCsv"
      @cancel="closeAction"
      :okText="$t('label.download')"
      cancelText="Cancel"
      v-model:visible="openUmsatzCsvModal"
      :closable="true"
      centered>
      <a-form
        v-ctrl-enter="submitDownloadUmsatzCsv"
        :ref="formRefFaktura"
        :model="formFaktura"
        :rules="rulesFaktura"
        @finish="submitDownloadUmsatzCsv"
        layout="vertical"
      >
        <a-spin :spinning="loading">
          <a-form-item
            ref="fakturaYear"
            name="fakturaYear"
            :label="$t('label.year')">
            <a-select v-model:value="formFaktura.fakturaYear" :placeholder="$t('label.year')" @change="formFaktura.fakturaMonth = undefined">
              <a-select-option v-for="year in getCurrentYearList" :key="year">
                {{ year }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            ref="fakturaMonth"
            name="fakturaMonth"
            :label="$t('label.month')">
            <a-select v-model:value="formFaktura.fakturaMonth" :placeholder="$t('label.month')">
              <a-select-option v-for="month in months" :key="month" :disabled="formFaktura.fakturaYear === getCurrentYear && months.indexOf(month) > getCurrentMonth">
                {{ $t('label.'+month) }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-spin>
      </a-form>
    </a-modal>
    <!-- Add Distributor Modal -->
    <a-modal
      :title="$t('label.distributor.add')"
      @ok="submitAddDistributor"
      @cancel="closeAction"
      okText="OK"
      cancelText="Cancel"
      destroyOnClose
      v-model:visible="openAddDistributorModal"
      :closable="true"
      centered>
      <a-form
        v-ctrl-enter="submitAddDistributor"
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="submitAddDistributor"
        layout="vertical"
      >
        <a-spin :spinning="loading">
          <a-form-item
            ref="distributorName"
            name="distributorName"
            :label="'Name'">
            <a-input
              v-model:value="form.distributorName"
              style="margin-top: 5px;"
              :placeholder="'Enter a name for the distributor'" />
          </a-form-item>
          <a-form-item
            ref="distributorCommission"
            name="distributorCommission"
            :label="$t('label.commission') + ' [%]'">
            <a-input-number
              v-model:value="form.distributorCommission"
              style="margin-top: 5px; width: 200px;"
              :min="0"
              :max="100"
              :placeholder="'Enter a commission value [%]'" />
          </a-form-item>
        </a-spin>
      </a-form>
    </a-modal>
    <!-- Edit Distributor Modal -->
    <a-modal
      :title="$t('label.distributor.edit')"
      @ok="submitEditDistributor"
      @cancel="closeAction"
      okText="OK"
      destroyOnClose
      cancelText="Cancel"
      v-model:visible="openEditDistributorModal"
      :closable="true"
      centered>
      <a-form
        v-ctrl-enter="submitAddDistributor"
        :ref="formRefEdit"
        :model="formEdit"
        :rules="rulesEdit"
        @finish="submitAddDistributor"
        layout="vertical"
      >
        <a-spin :spinning="loading">
          <a-form-item
            ref="distributorNameEdit"
            name="distributorNameEdit"
            :label="'Name'">
            <a-input
              v-model:value="formEdit.distributorNameEdit"
              style="margin-top: 5px;"
              :placeholder="'Enter a name for the distributor'" />
          </a-form-item>
          <a-form-item
            ref="distributorCommissionEdit"
            name="distributorCommissionEdit"
            :label="$t('label.commission')">
            <a-input-number
              v-model:value="formEdit.distributorCommissionEdit"
              style="margin-top: 5px; width: 200px;"
              :min="0"
              :max="100"
              :placeholder="'Enter a commission value (%)'" />
          </a-form-item>
        </a-spin>
      </a-form>
    </a-modal>
    <!-- Start of Table /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <a-card>
      <a-button
        :loading="loading"
        style="background: white"
        shape="round"
        size="small"
        @click="fetchData()">
        <template #icon><reload-outlined /></template>
        {{ $t('label.refresh') }}
      </a-button>
      <div style="display: flex; justify-content: flex-end; margin-bottom: 10px;">
        <div style="width: 300px; margin-right: 20px;">
        <a-input-search v-model:value="searchInput" placeholder="Suchtext..." @search="handleSearch()" size="default" >
          </a-input-search>
        </div>
        <a-button id="addDistributorBtn" type="primary" shape="round" @click="openAddDistributorModal=true">{{ $t('label.distributor.add') }}</a-button>
      </div>
      <a-table
        :columns="columns"
        :dataSource="distributorsList"
        @refresh="fetchData"
        :rowKey="record => record.Uuid">
        <template #ResellerAccounts="{ record }">
          <div v-if="record.ResellerAccounts !== null && record.ResellerAccounts.length > 0">
            <a-popover>
              <template #content>
                <span v-for="(account, index) in record.ResellerAccounts" :key="account.ID">
                  <span v-if="index !== (record.length-1) && index !== 0">
                    {{ ', ' + account.ResellerName }}
                  </span>
                  <span v-else>
                    {{ account.ResellerName }}
                  </span>
                </span>
              </template>
              <span v-if="record.ResellerAccounts.length === 1">{{ record.ResellerAccounts.length + ' ' + $t('label.account')}}</span>
              <span v-else>{{ record.ResellerAccounts.length + ' ' + $t('label.accounts')}}</span>
              <info-circle-outlined class="table-tooltip-icon" />
          </a-popover>
          </div>
        </template>
        <template #Articles="{ record }">
          <div v-if="record.Articles !== null && record.Articles.length > 0">
            <a-popover>
              <template #content>
                <span v-for="(account, index) in record.Articles" :key="account.ID">
                  <span style="white-space: pre" v-if="index !== (record.length-1) && index !== 0">
                    {{ '\n'+ account.ArticleNumber + ': ' + account.Description }}
                  </span>
                  <span v-else>
                    {{ account.ArticleNumber + ': ' + account.Description }}
                  </span>
                </span>
              </template>
              <span v-if="record.Articles.length === 1">{{ record.Articles.length + ' ' + $t('label.article')}}</span>
              <span v-else>{{ record.Articles.length + ' ' + $t('label.article')}}</span>
              <info-circle-outlined class="table-tooltip-icon" />
          </a-popover>
          </div>
        </template>
        <template #options="record">
          <span style="justify-content: center;">
            <a-dropdown>
              <a-button shape="circle" size="small" style="background-color: transparent; border-color: transparent;">
                <template #icon><MoreOutlined :style="{fontSize: '20px'}"/></template>
              </a-button>
              <template #overlay>
                <a-menu
                  class='user-menu-wrapper'>
                  <template v-for="action in actions" :key="action.key">
                    <a-menu-item
                      :danger="action.danger"
                      @click="execAction(record, action)">
                      <render-icon :icon="action.icon"/>
                        {{ $t(action.label) }}
                    </a-menu-item>
                  </template>
                </a-menu>
              </template>
            </a-dropdown>
          </span>
          <!-- <div class="actions">
            <tooltip-button
              :tooltip="$t('label.distributor.delete')"
              tooltipPlacement="left"
              icon="delete-outlined"
              type="primary"
              danger
              @onClick="openDeleteModal(record.Name, record.Uuid)" />
            <tooltip-button
              :tooltip="$t('label.distributor.edit')"
              tooltipPlacement="right"
              icon="edit-outlined"
              type="default"
              @onClick="openEditModal(record.Name, record.Uuid, record.Commission)" />
              <tooltip-button
              :tooltip="$t('label.distributor.download.csv')"
              tooltipPlacement="right"
              icon="download-outlined"
              type="default"
              @onClick="downloadArticlesCsv(record.Uuid)" />
          </div> -->
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { genericCompare } from '@/utils/sort.js'
import api2 from '@/wpApi/api2'
import { reactive, ref, toRaw } from 'vue'
import TooltipButton from '@/components/widgets/TooltipButton'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default {
  name: 'Distributors',
  components: {
    genericCompare,
    TooltipButton,
    DeleteOutlined
  },
  data () {
    return {
      actions: [
        {
          key: 1,
          action: 'edit',
          icon: 'edit-outlined',
          label: 'label.distributor.edit'
        },
        {
          key: 2,
          action: 'delete',
          icon: 'delete-outlined',
          label: 'label.distributor.delete',
          danger: true,
          disabled: true
        },
        {
          key: 3,
          action: 'download-distributor-csv',
          icon: 'download-outlined',
          label: 'label.distributor.download.csv'
        },
        {
          key: 4,
          action: 'download-faktora-csv',
          icon: 'download-outlined',
          label: 'label.distributor.download.faktura.csv'
        },
        {
          key: 5,
          action: 'download-artikelumsatz-csv',
          icon: 'download-outlined',
          label: 'label.distributor.download.turnover.csv'
        }
      ],
      editDistributor: undefined,
      selectedToDeleteName: undefined,
      selectedToDeleteUuid: undefined,
      openAddDistributorModal: false,
      openEditDistributorModal: false,
      openDeleteDistributorModal: false,
      openFakturaCsvModal: false,
      openUmsatzCsvModal: false,
      loggedInAccount: this.$store.getters.userInfo.accountid,
      loading: false,
      tableBackup: [],
      filteredList: [],
      distributorsList: [],
      items: [],
      searchInput: '',
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      columns: [
        {
          title: 'Name',
          dataIndex: 'Name',
          sorter: function (a, b) { return genericCompare(a[this.dataIndex] || '', b[this.dataIndex] || '') }
        },
        {
          title: 'UUID',
          dataIndex: 'Uuid'
        },
        {
          title: this.$t('label.commission') + ' [%]',
          dataIndex: 'Commission'
        },
        {
          title: this.$t('label.connected.accounts'),
          dataIndex: 'ResellerAccounts',
          slots: { customRender: 'ResellerAccounts' }
        },
        {
          title: this.$t('label.distributor.articles'),
          dataIndex: 'Articles',
          slots: { customRender: 'Articles' }
        },
        {
          title: this.$t('label.options'),
          dataIndex: 'options',
          slots: { customRender: 'options' }
        }
      ]
    }
  },
  computed: {
    getCurrentYearList: function () {
      // get current year and last year as a list
      var currentYear = this.getCurrentYear
      return [currentYear, currentYear - 1]
    },
    getCurrentYear: function () {
      var d = new Date()
      return d.getFullYear()
    },
    getCurrentMonth: function () {
      var d = new Date()
      return d.getMonth()
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        distributorName: undefined,
        distributorCommission: undefined
      })
      this.rules = reactive({
        distributorName: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateDistributorName, trigger: 'change' }
        ],
        distributorCommission: [
          { required: true, message: this.$t('message.error.required.input') }]
      })
      this.formRefEdit = ref()
      this.formEdit = reactive({
        distributorNameEdit: undefined,
        distributorCommissionEdit: undefined
      })
      this.rulesEdit = reactive({
        distributorNameEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateDistributorName, trigger: 'change' }
        ],
        distributorCommissionEdit: [
          { required: true, message: this.$t('message.error.required.input') }]
      })
      this.formRefFaktura = ref()
      this.formFaktura = reactive({
        fakturaYear: undefined,
        fakturaMonth: undefined
      })
      this.rulesFaktura = reactive({
        fakturaYear: [
          { required: true, message: this.$t('message.error.required.input') }
        ],
        fakturaMonth: [
          { required: true, message: this.$t('message.error.required.input') }]
      })
    },
    handleSearch () {
      this.filteredList = this.tableBackup.filter(x => x.Name.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase()))
      this.current = 1
      this.distributorsList = this.filteredList
      if (this.searchInput === '') {
        this.distributorList = this.tableBackup
      }
    },
    openEditModal (name, uuid, commission) {
      this.formEdit.distributorNameEdit = name
      this.formEdit.distributorCommissionEdit = commission
      this.editDistributor = uuid
      this.openEditDistributorModal = true
    },
    openDeleteModal (distName, distUuid) {
      this.selectedToDeleteName = distName
      this.selectedToDeleteUuid = distUuid
      this.openDeleteDistributorModal = true
    },
    openFakturaModal (uuid) {
      this.editDistributor = uuid
      this.formFaktura.fakturaYear = this.getCurrentYear
      this.openFakturaCsvModal = true
    },
    openUmsatzModal (uuid) {
      this.editDistributor = uuid
      this.formFaktura.fakturaYear = this.getCurrentYear
      this.openUmsatzCsvModal = true
    },
    async deleteDistributor () {
      if (this.selectedToDeleteUuid !== undefined) {
        var deleteParams = new FormData()
        deleteParams.append('distributorUuid', this.selectedToDeleteUuid)
        await api2.deleteDistributor(deleteParams).then(response => {
          this.$notification.success({
            message: this.$t('label.success'),
            description: this.$t('message.success.delete.distributor')
          })
          this.selectedToDeleteName = undefined
          this.selectedToDeleteUuid = undefined
          this.closeAction()
        }).catch(error => {
          this.selectedToDeleteName = undefined
          this.selectedToDeleteUuid = undefined
          this.closeAction()
          this.$notification.error({
            message: this.$t('label.error'),
            description: error.response.data,
            duration: 0
          })
        })
        this.fetchData()
      }
    },
    handleSearchReset () {
      this.searchInput = ''
      this.distributorsList = this.tableBackup
    },
    async validateDistributorName (rule, value) {
      const errorMessage = 'Der Distrutor-Name darf maximal 64 Zeichen enthalten!'
      if (value.length < 65 || value === undefined) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async fetchData () {
      this.loading = true
      await this.fetchDistributors()
      this.loading = false
    },
    async fetchDistributors () {
      this.loading = true
      await api2.listDistributors().then(response => {
        this.distributorsList = response.data
        this.tableBackup = response.data
        this.loading = false
      }).catch(error => {
        this.$notifyError(error.response.data)
        this.loading = false
      })
    },
    closeAction () {
      this.openAddDistributorModal = false
      this.openDeleteDistributorModal = false
      this.openEditDistributorModal = false
      this.formEdit.distributorNameEdit = undefined
      this.formEdit.distributorCommissionEdit = undefined
      this.editDistributor = undefined
      this.loading = false
    },
    submitAddDistributor (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.form)

        var params = new FormData()
        params.append('name', values.distributorName)
        params.append('commission', values.distributorCommission)
        await api2.createDistributor(params).then(response => {
          console.log('Distributor created')
          this.loading = false
          this.$notification.success({
            message: this.$t('label.success'),
            description: this.$t('message.success.create.distributor')
          })
          this.closeAction()
        }).catch(error => {
          this.$notification.error({
            message: this.$t('label.error'),
            description: error.response.data,
            duration: 0
          })
          this.closeAction()
        }).finally(() => {
          this.fetchData()
        })
      })
    },
    submitEditDistributor (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRefEdit.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.formEdit)

        var params = new FormData()
        params.append('distributorUuid', this.editDistributor)
        params.append('name', values.distributorNameEdit)
        params.append('commission', values.distributorCommissionEdit)
        await api2.editDistributor(params).then(response => {
          console.log('Distributor edited')
          this.loading = false
          this.$notification.success({
            message: this.$t('label.success'),
            description: this.$t('message.success.edit.distributor')
          })
          this.closeAction()
        }).catch(error => {
          this.$notification.error({
            message: this.$t('label.error'),
            description: error.response.data,
            duration: 0
          })
          this.closeAction()
        }).finally(() => {
          this.fetchData()
        })
      })
    },
    async downloadArticlesCsv (distributorUuid) {
      this.loading = true
      await api2.downloadDistributorCsv(distributorUuid).then(response => {
        var filename = response.headers['content-disposition'].split('filename=')[1]
        var blob = new Blob([response.data], { type: 'text/csv' })
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: this.$t('message.no.article.connected'),
          duration: 0
        })
      })
      this.loading = false
    },
    async submitDownloadFakturaCsv (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRefFaktura.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.formFaktura)
        var params = new FormData()
        params.append('distributorUuid', this.editDistributor)
        params.append('year', values.fakturaYear)
        params.append('month', values.fakturaMonth)
        await api2.downloadDistributorFakturaCsv(params).then(response => {
          var filename = response.headers['content-disposition'].split('filename=')[1]
          var blob = new Blob([response.data], { type: 'text/csv' })
          var url = window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
          this.openFakturaCsvModal = false
        }).catch(error => {
          console.log(error)
          this.openFakturaCsvModal = false
          this.$notification.error({
            message: this.$t('label.error'),
            description: this.$t('message.no.article.connected'),
            duration: 0
          })
        })
        this.openFakturaCsvModal = false
        this.loading = false
      })
    },
    async submitDownloadUmsatzCsv (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRefFaktura.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.formFaktura)
        var params = new FormData()
        params.append('distributorUuid', this.editDistributor)
        params.append('year', values.fakturaYear)
        params.append('month', values.fakturaMonth)
        await api2.downloadDistributorUmsatzCsv(params).then(response => {
          var filename = response.headers['content-disposition'].split('filename=')[1]
          var blob = new Blob([response.data], { type: 'text/csv' })
          var url = window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
          this.openFakturaCsvModal = false
        }).catch(error => {
          console.log(error)
          this.openFakturaCsvModal = false
          this.$notification.error({
            message: this.$t('label.error'),
            description: this.$t('message.no.article.connected'),
            duration: 0
          })
        })
        this.openUmsatzCsvModal = false
        this.loading = false
      })
    },
    execAction (record, action) {
      switch (action.action) {
        case 'edit':
          this.openEditModal(record.record.Name, record.record.Uuid, record.record.Commission)
          break
        case 'delete':
          this.openDeleteModal(record.record.Name, record.record.Uuid)
          break
        case 'download-distributor-csv':
          this.downloadArticlesCsv(record.record.Uuid)
          break
        case 'download-faktora-csv':
          this.openFakturaModal(record.record.Uuid)
          break
        case 'download-artikelumsatz-csv':
          this.openUmsatzModal(record.record.Uuid)
          break
        default:
          console.log('invalid Action')
          break
      }
    }
  }
}
</script>

<style scoped>
  .table-tooltip-icon {
    color: rgba(0,0,0,.45);
    margin-left: 5px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    width: 100px;
  }
  .ant-btn-round {
    background: #3D7CAF;
  }
</style>
